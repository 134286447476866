export const banners = [
  {
    image: "/imgs/banners/banner1.jpg",
    text: "¿Buscas a un asesor financiero certificado?",
    link: "/busca-tu-asesor-financiero-certificado",
    className: "Banner-col-left Banner-col-right"
  },
  {
    image: "/imgs/banners/banner2.jpg",
    text: "",
    link: "/certificacion",
    className: "Banner-col-left Banner-col-right"
  },
]
