import React from "react"
import ProgramsItem from "./ProgramsItem"

export default function Programs() {
  return (
    <div className="Programs">
      <div className="row efpa-row justify-content-center">
        <div className="col-12 col-md-3 Programs-col">
          <ProgramsItem
            title="EFP"
            text="European Financial Planner"
            link="/examenes-certificacion-efp"
            icon="fas fa-sitemap"
          />
        </div>
        <div className="col-12 col-md-3 Programs-col">
          <ProgramsItem
            title="EFA"
            text="European Financial Advisor"
            link="/examenes-certificacion-efa"
            icon="fas fa-user-tie"
          />
        </div>
        <div className="col-12 col-md-3 Programs-col">
          <ProgramsItem
            title="EIP"
            text="European Investment Practitioner"
            link="/examenes-certificacion-eip"
            icon="far fa-file-alt"
          />
        </div>
        <div className="col-12 col-md-3 Programs-col">
          <ProgramsItem
            title="EIA"
            text="European Investment Assistant"
            link="/examenes-certificacion-eia"
            icon="far fa-id-card"
          />
        </div>
        <div className="col-12 col-md-3 Programs-col">
          <ProgramsItem
            className="lcci"
            title="LCCI"
            text="Ley de contrato de Crédito Inmobiliario"
            link="/efpa-lcci"
            icon="far fa-id-card"
          />
        </div>
        <div className="col-12 col-md-3 Programs-col">
          <ProgramsItem
            className="esg"
            title="ESG"
            text="EFPA ESG Advisor™"
            link="/efpa-esg"
            icon="fab fa-envira"
          />
        </div>
      </div>
    </div>
  )
}
