import React, { useEffect, useState } from "react"
import Layout from "../Layout"
import Carousel from "../Carousel"
import HomeSlider from "../HomeSlider"
import Programs from "../Programs"
import Banner from "../Banner"
import BannerLink from "../Banner/BannerLink"
import TitleBlock from "../TitleBlock"
import SlidesNews from "../News/NewsSlide"
import InformationItem from "../Information"
import Calendar from "../Calendar"
import { banners } from "../../data/banners"
import { info1, info2 } from "../../data/information"
import Schedule from "../Schedule"
import { useAxiosClient } from "../../service/useAxiosClient"
import TitlePage from "../TitlePage"

export default function Home({ location }) {
  const [listNews, setListNews] = useState({})
  const [activitiesCategories, setActivitiesCategories] = useState([])
  const [activities, setActivities] = useState([])
  const [dates, setDates] = useState({})
  const [dateSelected, setDateSelected] = useState()
  const [sliders, setSliders] = useState([])

  const [, getNews] = useAxiosClient(
    {
      url: `/publications/web/home/?page=1&nitems=12`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getSliders] = useAxiosClient(
    {
      url: `/sliders/web/`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getCategories] = useAxiosClient(
    {
      url: `/activities/categories/web/`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getActivities] = useAxiosClient(
    {
      url: `/activities/web/calendar/`,
      method: "GET",
    },
    { manual: true }
  )

  const [, getDates] = useAxiosClient(
    {
      url: `activities/web/calendar/dates/`,
      method: "GET",
    },
    { manual: true }
  )

  useEffect(() => {
    getNews().then(({ data }) => data && setListNews(data))
    getSliders().then(({ data }) => data && data.length && setSliders(data))
    getCategories().then(
      ({ data }) => data && data.length && setActivitiesCategories(data)
    )
    getActivities().then(res => {
      setActivities(res.data)
    })
    getDates().then(({ data }) => data && setDates(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [, triggerEventFiltered] = useAxiosClient({}, { manual: true })

  const handleSelectEvent = dateSelected => {
    setDateSelected(dateSelected)
  }

  useEffect(() => {
    if (dateSelected)
      triggerEventFiltered({
        url: `/activities/web/calendar/`,
        params: {
          date: dateSelected,
        },
        method: "GET",
      })
        .then(({ data }) => setActivities(data))
        .catch(err => console.log("Error", err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelected])

  const renderSlidesNews = () => {
    return (
      listNews &&
      [1, 2, 3].map((item, index) => {
        return (
          <SlidesNews
            slides={listNews?.items?.slice(index * 4, (index + 1) * 4)}
          />
        )
      })
    )
  }

  const renderBanners = () => {
    return (
      banners &&
      banners.map(item => {
        return (
          <div className={`col-12 col-sm-4 ${item.className}`}>
            <Banner image={item.image} text={item.text} link={item.link} />
          </div>
        )
      })
    )
  }

  return (
    <Layout>
      <TitlePage
        title="EFPA, Asesores Financieros, EFA (European Financial Advisor)"
        url={location.pathname}
      />
      <HomeSlider slides={sliders} />
      <div className="container950">
        <Programs />
        <div className="Banners">
          <div className="row row efpa-row">
            {renderBanners()}
            <div className="col-12 col-sm-4 Banner-col-left">
              <Banner
                className="Banner-small"
                image="/imgs/banners/banner3.jpg"
                text="Cursos preparatorios para los exámenes"
                link="/centros-formativos-acreditados"
              />
              <Banner
                className="Banner-small"
                image="/imgs/banners/banner4.jpg"
                text="Cómo inscribirme a los exámenes"
                link="/como-inscribirme-examenes-efpa"
              />
              <BannerLink />
            </div>
            <div className="col-12 Banner-col-left Banner-col-right">
              <Banner
                className="Banner-small icon"
                useIcon
                image="/imgs/banners/banner6.jpg"
                text="Próximas convocatorias de exámenes"
                link="/fechas-examenes"
              />
            </div>
          </div>
        </div>
        <div className="Activities mb-4">
          <TitleBlock
            className="Topicality-title"
            title="Calendario de actividades"
            icon="far fa-calendar-alt"
          />
          <div className="row efpa-row">
            <div className="col-12 col-md-6 Banner-col-left Banner-col-right">
              <Calendar dates={dates} onChange={handleSelectEvent} />
            </div>
            <div className="col-12 col-md-6 pl-0 Banner-col-right">
              <Schedule
                scheduleList={activities}
                title={"Próximas actividades"}
                selectedDate={dateSelected}
                types={activitiesCategories}
              />
            </div>
          </div>
        </div>
        <div className="Topicality d-none d-lg-block">
          <TitleBlock
            className="Topicality-title"
            title="Actualidad"
            icon="far fa-newspaper"
          />
          <Carousel
            itemWidth={950}
            arrowRight={<i className="fas fa-arrow-right"></i>}
            arrowLeft={<i className="fas fa-arrow-left"></i>}
            className="Topicality-carousel"
            useArrows
          >
            {renderSlidesNews()}
          </Carousel>
        </div>
        <div className="Information d-none d-md-block">
          <div className="row efpa-row">
            <div className="col-12 col-md-6 p-0">
              <InformationItem
                title="EFPA España, Asociación Española de Asesores y Planificadores Financieros"
                info={info1}
              />
            </div>
            <div className="col-12 col-md-6 p-0">
              <InformationItem
                title="¿Qué es EFPA? Asesores Financieros, asesoría financiera independiente"
                info={info2}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
