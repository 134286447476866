import React from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"

export default function Banner(props) {
  const { className, link, image, text, useIcon } = props

  const renderText = () => {
    if (!useIcon) {
      return <figcaption className="Banner-text">{text}</figcaption>
    }
    return (
      <figcaption className="Banner-text">
        <i className="fas fa-pen-square"></i>
        {text}
      </figcaption>
    )
  }

  return (
    <div className={`Banner ${className}`}>
      <figure className="Banner-image">
        <Link className="Banner-link" to={link}>
          <img src={image} alt="Banner" />
          {renderText()}
        </Link>
      </figure>
    </div>
  )
}

Banner.propTypes = {
  className: Proptypes.string,
  link: Proptypes.string,
  image: Proptypes.string,
  text: Proptypes.string,
}

Banner.defaultProps = {
  className: "",
  link: "",
  image: "",
  text: "",
}
