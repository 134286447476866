import React from "react"
import NewsItem from "../News/NewsItem"

export default function NewsSlide(props) {
  const { slides } = props

  const renderNews = () => {
    return (
      slides &&
      slides.map(item => {
        const newtext = `${item.summary.slice(0, 100)}...`
        return (
          <div className="col-12 col-md-6 p-0">
            <NewsItem
              image={item.block1_image || "/imgs/default-image.jpg"}
              date={item.publication_date}
              title={item.title}
              category={item.category}
              text={newtext}
              link={`/actualidad/${item.category.pk}/${item.category.slug}/${item.id}/${item.slug}`}
              id={item?.id}
            />
          </div>
        )
      })
    )
  }
  return (
    <div className="NewsSlide">
      <div className="row efpa-row">{renderNews()}</div>
    </div>
  )
}
