import React from "react"
import Proptypes from "prop-types"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"

export default function HomeSlider(props) {
  const { slides } = props

  const renderSlides = () => {
    return (
      Array.isArray(slides) &&
      slides.map((item, index) => {
        return (
          <div key={index} className="HomeSlider-slide">
            <div
              style={{ position: "relative", height: "100%", width: "100%" }}
            >
              <picture>
                {/* A partir de 768 px cambia de imagen */}
                <source
                  media="(max-width: 768px)"
                  srcSet={item?.image_mobile}
                />
                <source media="(min-width: 769px)" srcSet={item?.image} />
                <img
                  src={item?.image}
                  alt="Slider"
                  style={{
                    width: "100%",
                    height: "356px",
                    margin: "auto",
                  }}
                />
              </picture>
              <div
                className="HomeSlider-content"
                style={{
                  zIndex: "95",
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translate(-50%)",
                }}
              >
                <h1 className={item?.title ? "HomeSlider-title" : "d-none"}>
                  {item?.title}
                </h1>
                <p
                  className={item?.subtitle ? "HomeSlider-subtitle" : "d-none"}
                >
                  {item?.subtitle}
                </p>
                <a
                  style={{ textDecoration: "None" }}
                  className="HomeSlider-link"
                  href={item?.link}
                >
                  {item?.link_text || "Más información"}
                </a>
              </div>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <div className="HomeSlider">
      <div className="HomeSlider-backdrop" />
      <Slider autoplay={5000} infinite>
        {renderSlides()}
      </Slider>
    </div>
  )
}

HomeSlider.defaultProptypes = {
  slides: [],
}

HomeSlider.propTypes = {
  slides: Proptypes.array,
}
