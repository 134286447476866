import React from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"

export default function ProgramsItem(props) {
  const { className, link, icon, title, text } = props
  return (
    <div className={`ProgramsItem ${className}`}>
      <Link className="ProgramsItem-link" to={link}>
        <i className={icon}></i>
        <p className="ProgramsItem-text">
          <span className="ProgramsItem-title">{title}</span>
          {text}
        </p>
      </Link>
    </div>
  )
}

ProgramsItem.propTypes = {
  className: Proptypes.string,
  link: Proptypes.string,
  icon: Proptypes.string,
  title: Proptypes.string,
  text: Proptypes.string,
}

ProgramsItem.defaultProps = {
  className: "",
  link: "/",
  icon: "",
  title: "",
  text: "",
}
