import { Link } from "gatsby"
import React from "react"

export default function BannerLink() {
  return (
    <div className="BannerLink">
      <Link to="http://edu.efpa.es/" className="BannerLink-link">
        <div className="BannerLink-content">
          <i className="fas fa-file-alt"></i>
          <p className="BannerLink-text">
            Programa EFPA de Educación Financiera
          </p>
        </div>
      </Link>
    </div>
  )
}
